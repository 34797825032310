<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DE CONTROL HORARIO REGISTRADAS</span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico far fa-clock fa-3x"></i>
                            <br>
                            <span class="lg-numero">{{listaHorariosRegistrados.length}}</span>
                            <br>
                            <span class="text-muted">Jornada(s) registrada(s</span>
                        </b-col>
                    </b-row>

                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5"> Gestión de control horario</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col v-if=" checkPermissions('015-CON-HOR','c')==1"   md="4" class="my-2">
                            <b-button block @click="modalRegistrarRegistro = true" size="md" variant="custom">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br>Nueva registro
                            </b-button>
                        </b-col>
                        <b-col md="4" class="my-2">
                            <b-button block @click="modalDatosEmpresa = true" size="md" variant="custom">
                                <i style="inline-size: auto" class="fas fa-list fa-3x pb-1"></i><br>Datos empresa
                            </b-button>
                        </b-col>
                        <b-col md="4" class="my-2">
                            <b-button block @click="modalInformeMensual = true" size="md" variant="custom">
                                <i style="inline-size: auto" class="fas fa-paste fa-3x pb-1"></i><br>Informe mensual
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Control de horarios registrados</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaHorariosRegistrados" :fields="campoHorariosRegistrados" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(opciones)="param">
                                    <b-button v-if=" checkPermissions('015-CON-HOR','u')==1"  @click="abrirModalActualizarRegistro(param)" class="mb-1 mr-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-button>
                                    <b-button v-if=" checkPermissions('015-CON-HOR','d')==1" @click="eliminarRegistro(param)" class="mb-1 mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                                <template v-slot:cell(tipo)="data">
                                    <span>{{comboTipo.filter(x => x.value == data.item.tipo)[0].text}}</span>
                                </template>
                                <template v-slot:cell(horaInicio)="data">
                                    <span v-if="data.item.tipo != 2 && data.item.tipo != 4 &&  data.item.tipo != 5">{{data.item.horaInicio}}</span>
                                </template>
                                <template v-slot:cell(horaSalida)="data">
                                    <span v-if="data.item.tipo != 2 && data.item.tipo != 4 &&  data.item.tipo != 5">{{data.item.horaSalida}}</span>
                                </template>
                                <template v-slot:cell(horaInvertidas)="data">
                                    <span v-if="data.item.tipo != 2 && data.item.tipo != 4 &&  data.item.tipo != 5">{{data.item.horaInvertidas}}</span>
                                </template>

                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>
    </b-row>

    <CModal :closeOnBackdrop="false" size="lg" color="primario" :show.sync="modalRegistrarRegistro">
        <template #header>

            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo registro </span>
            </h6>
            <CButtonClose @click="modalRegistrarRegistro = false" class="text-white" />
        </template>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarRegistro)">
                <b-row>
                    <b-col md="4">
                        <validation-provider name="trabajador" rules="required" v-slot="{errors}">
                            <b-form-group label="Trabajador:" class="mb-2">
                                <v-select :reduce="comboEmpleados =>comboEmpleados.idEmpleado" label="nombres" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosRegistro.idEmpleado , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosRegistro.idEmpleado" :options="comboEmpleados" @search:blur="blurTrabajador">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Recursos humanos'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="fecha" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha:" class="mb-2">
                                <b-form-input type="date" :state="getValidationState(validationContext)" size="md" v-model="datosRegistro.fecha"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="tipo" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Tipo:" class="mb-2">
                                <b-form-select :options="comboTipo" :state="getValidationState(validationContext)" v-model="datosRegistro.tipo">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosRegistro.tipo != 2 && datosRegistro.tipo != 4 && datosRegistro.tipo != 5 ">
                        <validation-provider name="hora inicio" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Hora inicio:" class="mb-2">
                                <b-form-input type="time" @change="calcularHoraInvertida()" :state="getValidationState(validationContext)" size="md" v-model="datosRegistro.horaInicio"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="4" v-if="datosRegistro.tipo != 2 && datosRegistro.tipo != 4 && datosRegistro.tipo != 5 ">
                        <validation-provider name="hora salida" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Hora salida:" class="mb-2">
                                <b-form-input type="time" @change="calcularHoraInvertida()" :state="getValidationState(validationContext)" size="md" v-model="datosRegistro.horaSalida"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="4" v-if="datosRegistro.tipo != 2 && datosRegistro.tipo != 4 && datosRegistro.tipo != 5 ">
                        <validation-provider name="hora invertidas" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Hora invertidas:" class="mb-2">
                                <b-form-input type="time" disabled class="disabled-white" :state="getValidationState(validationContext)" size="md" v-model="datosRegistro.horaInvertidas"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="observaciones" v-slot="validationContext">
                            <b-form-group label="Observaciones:" class="mb-2">
                                <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" size="md" v-model="datosRegistro.observaciones" placeholder="Ingrese las observaciones"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button size="md" :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalRegistrarRegistro = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>
    <CModal :closeOnBackdrop="false" size="lg" color="primario" :show.sync="modalActualizarRegistro">
        <template #header>

            <h6 class="modal-title">
                <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar registro </span>
            </h6>
            <CButtonClose @click="modalActualizarRegistro = false" class="text-white" />
        </template>

        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarRegistro)">
                <b-row>
                    <b-col md="4">
                        <validation-provider name="trabajador" rules="required" v-slot="{errors}">
                            <b-form-group label="Trabajador:" class="mb-2">
                                <v-select :reduce="comboEmpleados =>comboEmpleados.idEmpleado" label="nombres" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarRegistro.idEmpleado , 'style-invalid-select is-invalid': !!errors.length}" v-model=" datosActualizarRegistro.idEmpleado" :options="comboEmpleados" @search:blur="blurActualizarTrabajador">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Recursos humanos'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="fecha" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha:" class="mb-2">
                                <b-form-input type="date" :state="getValidationState(validationContext)" size="md" v-model="datosActualizarRegistro.fecha"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="tipo" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Tipo:" class="mb-2">
                                <b-form-select :options="comboTipo" :state="getValidationState(validationContext)" v-model="datosActualizarRegistro.tipo">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4" v-if="datosActualizarRegistro.tipo != 2 && datosActualizarRegistro.tipo != 4 && datosActualizarRegistro.tipo != 5 ">
                        <validation-provider name="hora inicio" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Hora inicio:" class="mb-2">
                                <b-form-input type="time" @change="calcularActualizarHoraInvertida()" :state="getValidationState(validationContext)" size="md" v-model="datosActualizarRegistro.horaInicio"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="4" v-if="datosActualizarRegistro.tipo != 2 && datosActualizarRegistro.tipo != 4 && datosActualizarRegistro.tipo != 5 ">
                        <validation-provider name="hora salida" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Hora salida:" class="mb-2">
                                <b-form-input type="time" @change="calcularActualizarHoraInvertida()" :state="getValidationState(validationContext)" size="md" v-model="datosActualizarRegistro.horaSalida"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="4" v-if="datosActualizarRegistro.tipo != 2 && datosActualizarRegistro.tipo != 4 && datosActualizarRegistro.tipo != 5 ">
                        <validation-provider name="hora invertidas" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Hora invertidas:" class="mb-2">
                                <b-form-input type="time" disabled class="disabled-white" :state="getValidationState(validationContext)" size="md" v-model="datosActualizarRegistro.horaInvertidas"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="observaciones" v-slot="validationContext">
                            <b-form-group label="Observaciones:" class="mb-2">
                                <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" size="md" v-model="datosActualizarRegistro.observaciones" placeholder="Ingrese las observaciones"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button size="md" :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalActualizarRegistro = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>
    <CModal :closeOnBackdrop="false" color="primario" :show.sync="modalDatosEmpresa">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Datos empresa</span></h6>
            <CButtonClose @click="modalDatosEmpresa = false" class="text-white" />
        </template>

        <validation-observer ref="observer3" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarControlHorarioEmpresa)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="horas convenio" :rules="{required: true, regex: /^\d*\.?\d*$/}" v-slot="validationContext">
                            <b-form-group label="Horas convenio:" class="mb-2">
                                <b-form-input type="text" @change="calcularHoraEmpresa()" :state="getValidationState(validationContext)" v-model="datosEmpresa.horasConvenio" size="md" placeholder="Ingrese hora de convenio"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="horas al día" :rules="{required: true, regex: /^\d*\,?\d*$/}" v-slot="validationContext">
                            <b-form-group label="Horas al día:" class="mb-2">
                                <b-form-input type="text" class="disabled-white" disabled :state="getValidationState(validationContext)" v-model="datosEmpresa.horasDia" size="md"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="jornadas al año" :rules="{required: true, regex: /^\d*\.?\d*$/}" v-slot="validationContext">
                            <b-form-group label="Jornadas al año:" class="mb-2">
                                <b-form-input type="text" @change="calcularHoraEmpresa()" :state="getValidationState(validationContext)" v-model="datosEmpresa.jornadaAnio" size="md" placeholder="Ingrese las jornadas al año"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="horas a la semana" :rules="{required: true, regex: /^\d*\,?\d*$/}" v-slot="validationContext">
                            <b-form-group label="Horas a la semana:" class="mb-2">
                                <b-form-input type="text" disabled class="disabled-white" :state="getValidationState(validationContext)" v-model="datosEmpresa.horasSemana" size="md"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="jornadas a la semana" :rules="{required: true, regex: /^\d*\.?\d*$/}" v-slot="validationContext">
                            <b-form-group label="Jornadas a la semana:" class="mb-2">
                                <b-form-input type="text" @change="calcularHoraEmpresa()" :state="getValidationState(validationContext)" v-model="datosEmpresa.jornadaSemana" placeholder="Ingrese las jornadas a la semana" size="md"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button size="md" :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalDatosEmpresa = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>
    <CModal :closeOnBackdrop="false" color="primario" :show.sync="modalInformeMensual">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-download fa-md"></i>
                <span class="h5"> Generación de informe mensual</span></h6>

            <CButtonClose @click="modalInformeMensual = false" class="text-white" />
        </template>

        <validation-observer ref="observer4" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(generarInforme)">
                <b-row>
                    <b-col lg="12">
                        <validation-provider name="trabajador" rules="required" v-slot="{errors}">
                            <b-form-group label="Trabajador:" class="mb-2">
                                <v-select :reduce="comboEmpleados =>comboEmpleados.idEmpleado" label="nombres" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosInformeMensual.trabajador , 'style-invalid-select is-invalid': !!errors.length}" v-model=" datosInformeMensual.trabajador" :options="comboEmpleados" @search:blur="blurInformeTrabajador">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="meses" rules="required" v-slot="{errors}">
                            <b-form-group label="Mes:" class="mb-2">
                                <v-select label="descripcion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosInformeMensual.mes , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosInformeMensual.mes" :options="comboMeses" @search:blur="blurMeses">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="año" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Año:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" v-model="datosInformeMensual.anio" size="md" placeholder="Ingrese el año"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                            Descargar informe
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalInformeMensual = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
    import XLSX from 'sheetjs-style';

export default {
    components: {
        
    },
    data() {
        return {
            modalRegistrarRegistro: false,
            modalActualizarRegistro: false,
            modalDatosEmpresa: false,
            modalInformeMensual: false,
            disabled: false,

            listaHorariosRegistrados: [],
            listaHorariosRegistradosGlobal: [],

            totalRows: 1,
            currentPage: 1,
            porPagina: 10,
            filter: null,
            filterOn: [],
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            comboEmpleados: [],
            comboMeses: [{
                    idMes: '1',
                    descripcion: 'Enero'
                },
                {
                    idMes: '2',
                    descripcion: 'Febrero'
                },
                {
                    idMes: '3',
                    descripcion: 'Marzo'
                },
                {
                    idMes: '4',
                    descripcion: 'Abril'
                },
                {
                    idMes: '5',
                    descripcion: 'Mayo'
                },
                {
                    idMes: '6',
                    descripcion: 'Junio'
                },
                {
                    idMes: '7',
                    descripcion: 'Julio'
                },
                {
                    idMes: '8',
                    descripcion: 'Agosto'
                },
                {
                    idMes: '9',
                    descripcion: 'Septiembre'
                },
                {
                    idMes: '10',
                    descripcion: 'Octubre'
                },
                {
                    idMes: '11',
                    descripcion: 'Noviembre'
                },
                {
                    idMes: '12',
                    descripcion: 'Diciembre'
                }
            ],
            comboTipo: [{
                    value: 1,
                    text: 'Normal'
                },
                {
                    value: 2,
                    text: 'No laborable'
                },
                {
                    value: 3,
                    text: 'Descanso'
                },
                {
                    value: 4,
                    text: 'Baja laboral'
                },
                {
                    value: 5,
                    text: 'Vacaciones'
                },
                {
                    value: 6,
                    text: 'Ausencia justificada'
                },
                {
                    value: 7,
                    text: 'Ausencia sin justificar'
                }
            ],

            campoHorariosRegistrados: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "fecha",
                    label: "Fecha",
                    class: "text-center",
                }, {
                    key: "nombres",
                    label: "Trabajador",
                    class: "text-center",
                }, {
                    key: "tipo",
                    label: "Tipo registro",
                    class: "text-center",
                }, {
                    key: "horaInicio",
                    label: "Hora inicio",
                    class: "text-center",
                }, {
                    key: "horaSalida",
                    label: "Hora salida",
                    class: "text-center",
                }, {
                    key: "horaInvertidas",
                    label: "Horas",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],

            datosInformeMensual: {
                trabajador: '',
                mes: null,
                anio: JSON.parse(localStorage.settings).yExcs
            },

            datosRegistro: {
                idCliente: '',
                idEmpleado: null,
                fecha: moment().format('YYYY-MM-DD'),
                tipo: null,
                horaInicio: moment().format('HH:mm'),
                horaSalida: moment().format('HH:mm'),
                horaInvertidas: '00:00',
                observaciones: ''            },
            datosActualizarRegistro: {
                idControlHorario: '',
                idCliente: '',
                idEmpleado: null,
                fecha: '',
                tipo: null,
                horaInicio: '',
                horaSalida: '',
                horaInvertidas: '',
                observaciones: ''            },
            datosEmpresa: {
                idControlHorarioEmpresa: '',
                idCliente: '',
                horasConvenio: '',
                jornadaAnio: '',
                jornadaSemana: '',
                horasDia: '',
                horasSemana: '',
            },

        }
    },
    methods: {
       checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        calcularHoraEmpresa() {
            let x = /^\d*\.?\d*$/
            if (this.datosEmpresa.horasConvenio != '' && this.datosEmpresa.jornadaAnio != '' && this.datosEmpresa.jornadaSemana != '') {
                if (x.test(this.datosEmpresa.horasConvenio) && x.test(this.datosEmpresa.horasConvenio) && x.test(this.datosEmpresa.horasConvenio)) {

                    let horasConvenio = parseFloat(this.datosEmpresa.horasConvenio).toFixed(2);
                    let diasAnio = parseFloat(this.datosEmpresa.jornadaAnio).toFixed(2);
                    let diasSemana = parseFloat(this.datosEmpresa.jornadaSemana).toFixed(2);

                    let horasDia = parseFloat(horasConvenio / diasAnio).toFixed(2);
                    let horasSemana = parseFloat(horasDia * diasSemana).toFixed(2);

                    horasDia = horasDia.replace('.', ',');
                    horasSemana = horasSemana.replace('.', ',');

                    this.datosEmpresa.horasDia = horasDia;

                    this.datosEmpresa.horasSemana = horasSemana;
                }
            }
        },
        calcularHoraInvertida() {
            if (this.datosRegistro.horaSalida !== '' && this.datosRegistro.horaInicio !== '') {
                let inicio = this.convertirFechaGMT(this.datosRegistro.horaInicio);
                let fin = this.convertirFechaGMT(this.datosRegistro.horaSalida);
                if (fin < inicio) {
                    fin.setDate(fin.getDate() + 1);
                }
                let tiempoRealizado = fin - inicio;
                let horas = Math.floor(tiempoRealizado / 1000 / 60 / 60);
                tiempoRealizado -= horas * 1000 * 60 * 60;
                let minutos = Math.floor(tiempoRealizado / 1000 / 60);
                this.datosRegistro.horaInvertidas = this.generarHoraInvertida(horas, minutos)
            }
        },
        calcularActualizarHoraInvertida() {
            if (this.datosActualizarRegistro.horaSalida !== '' && this.datosActualizarRegistro.horaInicio !== '') {
                let inicio = this.convertirFechaGMT(this.datosActualizarRegistro.horaInicio);
                let fin = this.convertirFechaGMT(this.datosActualizarRegistro.horaSalida);
                if (fin < inicio) {
                    fin.setDate(fin.getDate() + 1);
                }
                let tiempoRealizado = fin - inicio;
                let horas = Math.floor(tiempoRealizado / 1000 / 60 / 60);
                tiempoRealizado -= horas * 1000 * 60 * 60;
                let minutos = Math.floor(tiempoRealizado / 1000 / 60);
                this.datosActualizarRegistro.horaInvertidas = this.generarHoraInvertida(horas, minutos)
            }
        },
        convertirFechaGMT(hora) {
            hora = hora.split(':')
            hora = new Date(2021, 2, 10, hora[0], hora[1], 0);
            return hora;
        },
        generarHoraInvertida(horas, minutos) {
            if (horas < 10) {
                horas = '0' + horas;
            }
            if (minutos < 10) {
                minutos = '0' + minutos;
            }
            return horas + ':' + minutos;
        },
        calcularHoraTotal(arra, item) {
            let minutos = 0;
            let horas = 0;
            for (let i of arra) {
                let hora = i[item].split(':')
                horas += parseInt(hora[0]);
                minutos += parseInt(hora[1]);
            }
            let minutosr = minutos % 60;
            let minutosv = String(minutos / 60).split('.')[0];
            let horav = parseInt(minutosv) + horas;
            return this.generarHoraInvertida(horav, minutosr);
        },
        generarInforme() {
            this.disabled = true;

            let datoEmpleado = this.comboEmpleados.filter(x => x.idEmpleado == this.datosInformeMensual.trabajador)[0];
            let listaExcel = [];

            for (let i = 1; i <= 32; i++) {
                if (i < 32) {
                    listaExcel.push({
                        dia: i,
                        entrada: '',
                        salida: '',
                        totalHoras: '',
                        extras: '',
                        descanso: '',
                        ausenciaJustificada: '',
                        ausenciaInjustificada: '',
                    })
                }
            }

            let filtro = {
                idEmpleado: this.datosInformeMensual.trabajador,
                                mes: this.datosInformeMensual.mes.idMes,
            };

            let dataHorario = this.listaHorariosRegistradosGlobal.filter(function (item) {
                for (var key in filtro) {
                    if (item[key] === undefined || item[key] != filtro[key])
                        return false;
                }
                return true;
            });

            let arrayDias = [];
            for (let j in listaExcel) {
                for (let i in dataHorario) {

                    if (listaExcel[j].dia == parseInt(dataHorario[i].fecha.substr(8, 10))) {

                        listaExcel.push({
                            dia: parseInt(dataHorario[i].fecha.substr(8, 10)),
                            entrada: dataHorario[i].tipo == 2 || dataHorario[i].tipo == 5 || dataHorario[i].tipo == 4 ? this.comboTipo.filter(x => x.value == dataHorario[i].tipo)[0].text : dataHorario[i].horaInicio,
                            salida: dataHorario[i].tipo == 2 || dataHorario[i].tipo == 5 || dataHorario[i].tipo == 4 ? ' ' : dataHorario[i].horaSalida,
                            totalHoras: dataHorario[i].tipo == 1 || dataHorario[i].tipo == 6 ? dataHorario[i].horaInvertidas : '',
                            extras: dataHorario[i].tipo == 6 || dataHorario[i].tipo == 1 ? dataHorario[i].horaInvertidas : '',
                            descanso: dataHorario[i].tipo == 3 ? dataHorario[i].horaInvertidas : '',
                            ausenciaJustificada: dataHorario[i].tipo == 6 ? dataHorario[i].horaInvertidas : '',
                            ausenciaInjustificada: dataHorario[i].tipo == 7 ? dataHorario[i].horaInvertidas : '',
                        });
                        arrayDias.push({
                            dia: parseInt(dataHorario[i].fecha.substr(8, 10))
                        })
                    }
                }
            }
            listaExcel.sort(function (a, b) {
                return parseInt(a.dia) - parseInt(b.dia);
            });

            for (const i in listaExcel) {
                for (const j in arrayDias) {
                    if (listaExcel[i].dia == arrayDias[j].dia && listaExcel[i].entrada == '') {
                        listaExcel.splice(i, 1);
                    }
                }
            }
        
            listaExcel.push({
                dia: 'TOTAL',
                entrada: '',
                salida: '',
                totalHoras: listaExcel.filter(x => x.totalHoras != '') ? this.calcularHoraTotal(listaExcel.filter(x => x.totalHoras != ''), "totalHoras") : '00:00',
                extras: listaExcel.filter(x => x.totalHoras != '') ? this.calcularHoraTotal(listaExcel.filter(x => x.totalHoras != ''), "totalHoras") : '00:00',
                descanso: listaExcel.filter(x => x.descanso != '') ? this.calcularHoraTotal(listaExcel.filter(x => x.descanso != ''), "descanso") : '00:00',
                ausenciaJustificada: listaExcel.filter(x => x.ausenciaJustificada != '') ? this.calcularHoraTotal(listaExcel.filter(x => x.ausenciaJustificada != ''), "ausenciaJustificada") : '00:00',
                ausenciaInjustificada: listaExcel.filter(x => x.ausenciaInjustificada != '') ? this.calcularHoraTotal(listaExcel.filter(x => x.ausenciaInjustificada != ''), "ausenciaInjustificada") : '00:00',
            })

            //Create sheet
            let empleadosWS = XLSX.utils.json_to_sheet(listaExcel, {
                origin: "A11"
            })

            //Custom rows
            XLSX.utils.sheet_add_aoa(empleadosWS, [
                ['INFORME DE CONTROL HORARIO MENSUAL']
            ], {
                origin: 'A2'
            });
            XLSX.utils.sheet_add_aoa(empleadosWS, [
                ['TRABAJADOR:']
            ], {
                origin: 'A5'
            });
            XLSX.utils.sheet_add_aoa(empleadosWS, [
                ['']
            ], {
                origin: 'B5'
            });
            XLSX.utils.sheet_add_aoa(empleadosWS, [
                [datoEmpleado.nombre]
            ], {
                origin: 'A6'
            });
            XLSX.utils.sheet_add_aoa(empleadosWS, [
                ['']
            ], {
                origin: 'B6'
            });
            XLSX.utils.sheet_add_aoa(empleadosWS, [
                [datoEmpleado.apellidos]
            ], {
                origin: 'A7'
            });
            XLSX.utils.sheet_add_aoa(empleadosWS, [
                ['']
            ], {
                origin: 'B7'
            });
            XLSX.utils.sheet_add_aoa(empleadosWS, [
                ['DNI:']
            ], {
                origin: 'D5'
            });
            XLSX.utils.sheet_add_aoa(empleadosWS, [
                [datoEmpleado.numeroDocumento]
            ], {
                origin: 'E5'
            });
            XLSX.utils.sheet_add_aoa(empleadosWS, [
                ['Mes:']
            ], {
                origin: 'D7'
            });
            XLSX.utils.sheet_add_aoa(empleadosWS, [
                [this.datosInformeMensual.mes.descripcion]
            ], {
                origin: 'E7'
            });
            XLSX.utils.sheet_add_aoa(empleadosWS, [
                ['Año:']
            ], {
                origin: 'D9'
            });
            XLSX.utils.sheet_add_aoa(empleadosWS, [
                [this.datosInformeMensual.anio]
            ], {
                origin: 'E9'
            });

            //Replace header 

            empleadosWS["A11"].v = "Día";
            empleadosWS["B11"].v = "Entrada";
            empleadosWS["C11"].v = "Salida";
            empleadosWS["D11"].v = "Total Horas";
            empleadosWS["E11"].v = "Extras/Compl.";
            empleadosWS["F11"].v = "Descanso";
            empleadosWS["G11"].v = "Ausencias justificadas";
            empleadosWS["H11"].v = "Ausencias injustificadas";

            // Styles
            let x = 11;
            for (let i = 0; i <= listaExcel.length; i++) {
               
                empleadosWS[`A${x}`].s = {
                    border: {
                        top: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        bottom: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        right: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        left: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                    },
                    alignment: {
                        horizontal: 'center'
                    }
                };
                empleadosWS[`B${x}`].s = {
                    border: {
                        top: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        bottom: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        right: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        left: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                    },
                    alignment: {
                        horizontal: 'center'
                    }
                };
                empleadosWS[`C${x}`].s = {
                    border: {
                        top: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        bottom: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        right: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        left: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                    },
                    alignment: {
                        horizontal: 'center'
                    }
                };
                empleadosWS[`D${x}`].s = {
                    border: {
                        top: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        bottom: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        right: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        left: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                    },
                    alignment: {
                        horizontal: 'center'
                    }
                };
                empleadosWS[`E${x}`].s = {
                    border: {
                        top: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        bottom: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        right: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        left: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                    },
                    alignment: {
                        horizontal: 'center'
                    }
                };
                empleadosWS[`F${x}`].s = {
                    border: {
                        top: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        bottom: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        right: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        left: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                    },
                    alignment: {
                        horizontal: 'center'
                    }
                };
                empleadosWS[`G${x}`].s = {
                    border: {
                        top: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        bottom: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        right: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        left: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                    },
                    alignment: {
                        horizontal: 'center'
                    }
                };
                empleadosWS[`H${x}`].s = {
                    border: {
                        top: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        bottom: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        right: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        left: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                    },
                    alignment: {
                        horizontal: 'center'
                    }
                };
            x++;
            };
            empleadosWS[["A2"]].s = {
                font: {
                    name: 'Calibri',
                    sz: 13,
                    bold: true
                },
                alignment: {
                    horizontal: 'center'
                }
            };
            empleadosWS[["A5"]].s = {
                border: {
                    top: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    bottom: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    left: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                }
            };
            empleadosWS[["B5"]].s = {
                border: {
                    top: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    bottom: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    right: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                }
            };
            empleadosWS[["A6"]].s = {
                border: {
                    top: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    bottom: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    left: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                }
            };
            empleadosWS[["B6"]].s = {
                border: {
                    top: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    bottom: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    right: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                }
            };
            empleadosWS[["A7"]].s = {
                border: {
                    top: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    bottom: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    left: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                }
            };
            empleadosWS[["B7"]].s = {
                border: {
                    top: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    bottom: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    right: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                }
            };
            empleadosWS[["D5"]].s = {
                border: {
                    top: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    bottom: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    right: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    left: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                }
            };
            empleadosWS[["E5"]].s = {
                border: {
                    top: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    bottom: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    right: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    left: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                },
                alignment: {
                    horizontal: 'center'
                }
            };
            empleadosWS[["D7"]].s = {
                border: {
                    top: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    bottom: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    right: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    left: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                }
            };
            empleadosWS[["E7"]].s = {
                border: {
                    top: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    bottom: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    right: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    left: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                },
                alignment: {
                    horizontal: 'center'
                }
            };
            empleadosWS[["D9"]].s = {
                border: {
                    top: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    bottom: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    right: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    left: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                }
            };
            empleadosWS[["E9"]].s = {
                border: {
                    top: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    bottom: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    right: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    left: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                },
                alignment: {
                    horizontal: 'center'
                }
            };
            //merges
            // todo inicia en 0
            // s -> r-> donde inicia verticalmente
            // e -> r-> donde acaba verticalmente
            
            // s -> c-> donde inicia horizontalmente
            // e -> c-> donde acaba horizontalmente

            let empleadosWSMerges = [{
                    s: {
                        r: 1, 
                        c: 0
                    },
                    e: {
                        r: 1,
                        c: 7
                    },
                },
                {
                    s: {
                        r: 4,
                        c: 0
                    },
                    e: {
                        r: 4,
                        c: 1
                    }
                },
                {
                    s: {
                        r: 5,
                        c: 0
                    },
                    e: {
                        r: 5,
                        c: 1
                    }
                },
                {
                    s: {
                        r: 6,
                        c: 0
                    },
                    e: {
                        r: 6,
                        c: 1
                    }
                }
            ]
            let empleadosWSCols = [{
                    wch: 13
                },
                {
                    wch: 13
                },
                {
                    wch: 13
                },
                {
                    wch: 13
                },
                {
                    wch: 15
                }, {
                    wch: 13
                }, {
                    wch: 20
                }, {
                    wch: 20
                },
            ];

            empleadosWS['!cols'] = empleadosWSCols;
            empleadosWS['!merges'] = empleadosWSMerges;

            let wb = XLSX.utils.book_new()

            XLSX.utils.book_append_sheet(wb, empleadosWS, 'Informe')

            XLSX.writeFile(wb, 'Informe mensual del trabajador.xlsx')
            this.disabled = false;
        },

        listarEmpleados() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-empleados", {
                        params: {
                            idCliente: me.datosRegistro.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboEmpleados = response.data
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },

        registrarRegistro() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-controlhorario",
                    me.datosRegistro, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    me.modalRegistrarRegistro = false
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarRegistros();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;

                });
        },

        actualizarRegistro() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-controlhorario",
                    me.datosActualizarRegistro, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    me.modalActualizarRegistro = false
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarRegistros();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        listarRegistros() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-controlhorarios", {
                        params: {
                            idCliente: me.datosRegistro.idCliente                        },
                    }
                )
                .then(function (response) {
                    me.listaHorariosRegistrados = response.data;
                    me.listaHorariosRegistradosGlobal = response.data
                    me.totalRows = me.listaHorariosRegistrados.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!' )
                });
        },
        obtenerControlHorarioEmpresa() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-controlhorario-empresa", {
                        params: {
                            idCliente: me.datosRegistro.idCliente,
                        },
                    }
                )
                .then(function (response) {
                    if (response.data.length > 0) {
                        me.datosEmpresa.idControlHorarioEmpresa = response.data[0].idControlHorarioEmpresa;
                        me.datosEmpresa.horasConvenio = response.data[0].horasConvenio;
                        me.datosEmpresa.jornadaAnio = response.data[0].jornadaAnio;
                        me.datosEmpresa.jornadaSemana = response.data[0].jornadaSemana;
                        me.datosEmpresa.horasDia = response.data[0].horasDia;
                        me.datosEmpresa.horasSemana = response.data[0].horasSemana;
                    }

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!' )
                });
        },
        registrarControlHorarioEmpresa() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-controlhorario-empresa",
                    me.datosEmpresa, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.obtenerControlHorarioEmpresa();
                    me.disabled = false;
                    me.modalDatosEmpresa = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;

                });
        },
        abrirModalActualizarRegistro(param) {
            this.datosActualizarRegistro.idControlHorario = param.item.idControlHorario;
            this.datosActualizarRegistro.idEmpleado = param.item.idEmpleado;
            this.datosActualizarRegistro.horaInicio = param.item.horaInicio;
            this.datosActualizarRegistro.fecha = param.item.fecha;
            this.datosActualizarRegistro.horaSalida = param.item.horaSalida;
            this.datosActualizarRegistro.tipo = param.item.tipo;
            this.datosActualizarRegistro.horaInvertidas = param.item.horaInvertidas;
            this.datosActualizarRegistro.observaciones = param.item.observaciones;
            this.modalActualizarRegistro = true;
        },

        resetModalRegistrarRegistro() {
            this.datosRegistro.idEmpleado = null;
            this.datosRegistro.horaInicio = moment().format('HH:mm');
            this.datosRegistro.fecha = moment().format('YYYY-MM-DD');
            this.datosRegistro.horaSalida = moment().format('HH:mm');
            this.datosRegistro.tipo = null;
            this.datosRegistro.horaInvertidas = '00:00';
            this.datosRegistro.observaciones = '';
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });

        },
        resetModalActualizarRegistro() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },
        resetModalDatosEmpresa() {
            this.$nextTick(() => {
                this.$refs.observer3.reset();
            });
            if (this.datosEmpresa.idControlHorarioEmpresa == '') {
                this.datosEmpresa.horasConvenio = '';
                this.datosEmpresa.jornadaAnio = '';
                this.datosEmpresa.jornadaSemana = '';
                this.datosEmpresa.horasSemana = '';
                this.datosEmpresa.horasDia = '';
            }
        },

        resetModalInformeMensual() {
            this.datosInformeMensual.trabajador = null;
            this.datosInformeMensual.mes = this.comboMeses.filter(x => x.idMes == moment().format('M'))[0];
            this.datosInformeMensual.anio = JSON.parse(localStorage.settings).yExcs;
            this.$nextTick(() => {
                this.$refs.observer4.reset();
            });
        },

        blurTrabajador() {
            this.computedForm.refs.trabajador.validate();
        },
        blurActualizarTrabajador() {
            this.computedActualizarForm.refs.trabajador.validate();
        },
        blurInformeTrabajador() {
            this.computedForm4.refs.trabajador.validate();
        },

        blurMeses() {
            this.computedForm4.refs.meses.validate();
        },
        eliminarRegistro(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el registro?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let me = this;
                    axios
                        .post(
                            CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-controlhorario", {
                                idControlHorario: param.item.idControlHorario
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.token}`
                                },
                            }
                        )
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarRegistros();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedActualizarForm() {
            return this.$refs.observer2;
        },
        computedForm4() {
            return this.$refs.observer4;
        }
    },
    watch: {
        modalRegistrarRegistro: function (val) {
            if (val == false) {
                this.resetModalRegistrarRegistro();
            }
        },
        modalActualizarRegistro: function (val) {
            if (val == false) {
                this.resetModalActualizarRegistro();
            }
        },
        modalInformeMensual: function (val) {
            if (val == false) {
                this.resetModalInformeMensual();
            }
        },
        modalDatosEmpresa: function (val) {
            if (val == false) {
                this.resetModalDatosEmpresa();
            }
        }

    },
    mounted() {
             if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosRegistro.idCliente = this.datosActualizarRegistro.idCliente = this.datosEmpresa.idCliente = user.uidClient;
            // this.listarTareas();
            this.listarEmpleados();
            this.listarRegistros();
            this.obtenerControlHorarioEmpresa();
            this.datosInformeMensual.mes = this.comboMeses.filter(x => x.idMes == moment().format('M'))[0];
        }
    }

}
</script>
